<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="dfsUrl" slot-scope="{row}" :src="row.goods.dfsUrl || ''" :src-list="[row.goods.dfsUrl]" />
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'Exchange',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      statusArray: { '-1': '已取消', '0': '未领取', '1': '已完成' },
      listQuery: {
        param: {
          hasTenant: true,
          goodsId: this.$route.params.goodsId
        }
      },
      tableColumns: [
        {
          prop: 'checkoutCode',
          title: '核销码',
          minWidth: 150
        },
        {
          title: '商品名称',
          formatter: (row, column) => {
            return row.goods && row.goods.title ? row.goods.title : ''
          },
          minWidth: 200
        },
        {
          title: '商品图片',
          slot: 'dfsUrl'
        },
        {
          title: '商品兑换地址',
          formatter: (row, column) => {
            return row.goods && row.goods.address ? row.goods.address : ''
          },
          minWidth: 150
        },
        {
          title: '商品负责人',
          formatter: (row, column) => {
            if (!row.goods) {
              return ''
            }
            let text = row.goods.contactPerson || ''
            text += row.goods.contactPerson && row.goods.contactPhone ? ' <br/> ' : ''
            text += row.goods.contactPhone || ''
            return text
          },
          minWidth: 150
        },
        {
          title: '兑换用户',
          formatter: (row, column) => {
            if (!row.user) {
              return ''
            }
            let text = row.user.name || ''
            text += row.user.name && row.user.phone ? ' <br/> ' : ''
            text += row.user.phone || ''
            return text
          },
          minWidth: 150
        },
        {
          prop: 'count',
          title: '兑换数量',
          minWidth: 100
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.status in this.statusArray ? this.statusArray[row.status] : ''
          },
          minWidth: 100
        },
        {
          prop: 'createTime',
          title: '兑换时间',
          minWidth: 160
        },
        {
          prop: 'checkoutTime',
          title: '核销时间',
          minWidth: 160
        }
      ],
      goodsFuzzySearchProps: [{ prop: 'title', label: '标题' }]
    }
  },
  methods: {
    ...mapActions(['goodsPublicPageList', 'goodsExchangePageList', 'goodsExchangeCheckout']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    goodsFuzzySearchData(param, cb) {
      this.goodsPublicPageList(param).then(res => {
        cb(res)
      }).catch(() => {})
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.goodsExchangePageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
